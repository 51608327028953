/* The Overlay (background) */
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 20%;
  width: 320px;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  right: 0;
  top: 0;
  background-color: rgb(248, 110, 42); /* Black fallback color */
  background-color: rgba(248, 110, 42, 0.8); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  border-right: solid #2ba130;
}

/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  top: 10%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlay */
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 22px;
  color: #818181;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
  /*text-align: -webkit-center;*/
}

.menu-btn {
  padding: 8px;
  margin-bottom: 2%;
  margin-top: 2%;
  text-decoration: none;
  font-size: 22px;
  color: #ffffff;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
  margin-left: auto;
  margin-right: auto;
  background-color: green;
}

.menu-btn:hover {
  background-color: #2ba130;
  color: #ffffff;
}
/* When you mouse over the navigation links, change their color */
.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 60px;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

.menu-button {
  height: 70px;
  width: 70px;
  position: absolute;
  right: 5px;
  margin-top: 5px;
}
.menu-button:hover {
  background-color: rgb(248, 110, 42);
}

.menu-button-line {
  width: 100%;
  height: 5px;
  background-color: black;
  margin: 7px 0;
}
.size-buttons {
  width: 40%;
  margin-left: 5%;
  margin-right: 5%;
}

.dropdown-menu:hover {
  cursor: pointer;
}

.btn-group-curtain {
  width: 95%;
}

.reload-button {
  line-height: 12px;
  width: 18px;
  font-size: 8pt;
}
/*
@font-face {
  font-family: "ARDECODE";
  src: local("ARDECODE"), url(/src/fonts/ardecode.ttf) format("truetype");
}
*/
